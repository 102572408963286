/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { StoredViewUserConfiguration } from '../models/ETG_SABENTISpro_Application_Core_models';
import { StoredConfigurationsResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ViewsPluginRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ViewUserConfiguration } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ViewExecutableCountResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ViewsExecutionRequestOptions } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ViewsExecutionResponse } from '../models/ETG_SABENTISpro_Application_Core_models';
import { CoreHashedKey } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ViewsVboSelectedItem } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class ViewsService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Delete the view template
  *
  * @param {string} template
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {boolean}
  */
  deleteDeleteconfigurationtemplate(template: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<boolean>>
  {
    const params: any = {'template' : template};
    return this.generalService.delete(`core-view/delete-configuration-template`, params, clientOpts) as Observable<WebServiceResponseTyped<boolean>>;
  }

  /**
  *
  * Delete the current storage for the view using the storage key
  *
  * @param {string} storageKey
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {boolean}
  */
  deleteDeletestoredconfiguration(storageKey: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<boolean>>
  {
    const params: any = {'storageKey' : storageKey};
    return this.generalService.delete(`core-view/delete-stored-configuration`, params, clientOpts) as Observable<WebServiceResponseTyped<boolean>>;
  }

  /**
  *
  * Get the templates available for the current user and view
  *
  * @param {string} template
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {StoredViewUserConfiguration}
  */
  getGetconfigurationtemplate(template: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<StoredViewUserConfiguration>>
  {
    const params: any = {'template' : template};
    return this.generalService.get(`core-view/get-configuration-template`, params, clientOpts) as Observable<WebServiceResponseTyped<StoredViewUserConfiguration>>;
  }

  /**
  *
  * Gets the current configuration for the current user and view
  *
  * @param {string} viewId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {StoredConfigurationsResult}
  */
  getGetconfigurationtemplatesinfo(viewId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<StoredConfigurationsResult>>
  {
    const params: any = {'viewId' : viewId};
    return this.generalService.get(`core-view/get-configuration-templates-info/${viewId}`, params, clientOpts) as Observable<WebServiceResponseTyped<StoredConfigurationsResult>>;
  }

  /**
  *
  * Count the total results of a View
  *
  * @param {ViewsPluginRequest} pluginRequest
  * @param {string} viewId
  * @param {ViewUserConfiguration} userConfiguration
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {ViewExecutableCountResult}
  */
  postCount(pluginRequest: ViewsPluginRequest, viewId: string, userConfiguration: ViewUserConfiguration = null, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<ViewExecutableCountResult>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'viewId' : viewId, 'userConfiguration' : JSON.stringify(userConfiguration)};
    return this.generalService.post(`core-view/count/${viewId}`, params, clientOpts) as Observable<WebServiceResponseTyped<ViewExecutableCountResult>>;
  }

  /**
  *
  * Get the configuration of a listing by your ID
  *
  * @param {ViewsPluginRequest} pluginRequest Information about the requested plugin
  * @param {ViewsExecutionRequestOptions} pluginRequestOptions
  * @param {string} viewId
  * @param {ViewUserConfiguration} userConfiguration The user configuration
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {ViewsExecutionResponse} General configuration of the list and the specific configuration of the user (if any)
  */
  postLoad(pluginRequest: ViewsPluginRequest, pluginRequestOptions: ViewsExecutionRequestOptions, viewId: string, userConfiguration: ViewUserConfiguration = null, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<ViewsExecutionResponse>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'pluginRequestOptions' : JSON.stringify(pluginRequestOptions), 'viewId' : viewId, 'userConfiguration' : JSON.stringify(userConfiguration)};
    return this.generalService.post(`core-view/load/${viewId}`, params, clientOpts) as Observable<WebServiceResponseTyped<ViewsExecutionResponse>>;
  }

  /**
  *
  * Save the current configuration for the View and the template
  *
  * @param {StoredViewUserConfiguration} configuration
  * @param {string} viewId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {StoredViewUserConfiguration}
  */
  postSaveconfigurationastemplate(configuration: StoredViewUserConfiguration, viewId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<StoredViewUserConfiguration>>
  {
    const params: any = {'configuration' : JSON.stringify(configuration), 'viewId' : viewId};
    return this.generalService.post(`core-view/save-configuration-as-template/${viewId}`, params, clientOpts) as Observable<WebServiceResponseTyped<StoredViewUserConfiguration>>;
  }

  /**
  *
  * Materialize the items of a ViewsBulkOperation
  *
  * @param {ViewsPluginRequest} pluginRequest Information about the requested plugin
  * @param {ViewUserConfiguration} userConfiguration The user configuration
  * @param {CoreHashedKey[]} identifiers
  * @param {string} viewId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {ViewsVboSelectedItem[]} General configuration of the list and the specific configuration of the user (if any)
  */
  postVbomaterializefromidentifiers(pluginRequest: ViewsPluginRequest, userConfiguration: ViewUserConfiguration, identifiers: CoreHashedKey[], viewId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<ViewsVboSelectedItem[]>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'userConfiguration' : JSON.stringify(userConfiguration), 'identifiers' : JSON.stringify(identifiers), 'viewId' : viewId};
    return this.generalService.post(`core-view/vbo-materialize-from-identifiers/${viewId}`, params, clientOpts) as Observable<WebServiceResponseTyped<ViewsVboSelectedItem[]>>;
  }

  /**
  *
  * Materialize the items of a ViewsBulkOperation
  *
  * @param {ViewsPluginRequest} pluginRequest Information about the requested plugin
  * @param {ViewUserConfiguration} userConfiguration The user configuration
  * @param {string} viewId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {ViewsVboSelectedItem[]} General configuration of the list and the specific configuration of the user (if any)
  */
  postVbomaterializeinmemory(pluginRequest: ViewsPluginRequest, userConfiguration: ViewUserConfiguration, viewId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<ViewsVboSelectedItem[]>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'userConfiguration' : JSON.stringify(userConfiguration), 'viewId' : viewId};
    return this.generalService.post(`core-view/vbo-materialize-in-memory/${viewId}`, params, clientOpts) as Observable<WebServiceResponseTyped<ViewsVboSelectedItem[]>>;
  }

  /**
  *
  * Materialize the items of a ViewsBulkOperation. Returns the ID of the CORE_BO
  *
  * @param {ViewsPluginRequest} pluginRequest Information about the requested plugin
  * @param {ViewUserConfiguration} userConfiguration The user configuration
  * @param {string} viewId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {string} General configuration of the list and the specific configuration of the user (if any)
  */
  postVbomaterializeoperation(pluginRequest: ViewsPluginRequest, userConfiguration: ViewUserConfiguration, viewId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<string>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'userConfiguration' : JSON.stringify(userConfiguration), 'viewId' : viewId};
    return this.generalService.post(`core-view/vbo-materialize-operation/${viewId}`, params, clientOpts) as Observable<WebServiceResponseTyped<string>>;
  }
}
