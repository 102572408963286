import { ChangeDetectorRef, Component, ElementRef, ViewEncapsulation } from '@angular/core';

import { ListComponent2Service } from '../../../list.service';
import { DestroyableObjectTrait } from '../../../../utils/destroyableobject.trait';
import { IViewHeaderComponent } from '../../view-header-component.interface';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { IVboOperation, ViewModeTypeEnum } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { CORE_QUEUE } from '../../../../../core/models/ETG_SABENTISpro_Models_models';
import { ActiveUserConfigurationClass } from '../../../activeUserConfigurationClass';
import { takeUntil } from 'rxjs/operators';

const titleTooltip: string = 'Refrescar';

/**
 * Parent filter component
 * contains filter component and configuration component
 * emits and retrieves configuration changes
 */
@Component({
  selector: 'app-view-material-bar',
  templateUrl: './view-material-bar.component.html',
  styleUrls: ['./view-material-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ViewMaterialBarComponent extends DestroyableObjectTrait implements IViewHeaderComponent {

  public showTools: boolean = true;

  public ViewModeTypeEnum = ViewModeTypeEnum;

  /**
   * The current configuration is stored into de Views Storage
   */
  currentUserConfigurationIsCustomized: boolean = false;

  constructor(
      public listComponentService: ListComponent2Service,
      private cdr: ChangeDetectorRef,
      private _elementRef: ElementRef) {
    super();

    this.listComponentService
        .activeUserConfiguration
        .pipe(
            takeUntil(this.componentDestroyed$),
        )
        .subscribe((next: ActiveUserConfigurationClass) => {
          this.currentUserConfigurationIsCustomized = next.currentUserConfigurationIsCutomized();
        });
  }

  getElementRef(): ElementRef {
    return this._elementRef;
  }

  /**
   * Refresh the view without modify searching and page
   */
  refreshView(): void {
    this.listComponentService.refresh();
  }

  /**
   * Returns a string with the content for the tooltip.
   */
  get tooltip(): string {
    return titleTooltip;
  }

  changeShowTools(): void {
    this.showTools = !this.showTools;
    this.cdr.detectChanges();
  }

  dispatchEventOnTaskCompleted(operationResult: {
    operation: IVboOperation,
    result: { id: string, message: any[], responseData: any } | CORE_QUEUE
  }): void {
    this.listComponentService.emitOnVboOperationCompleted(operationResult);
  }
}
