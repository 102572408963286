import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import {
  CoreHashedKey,
  DtoFrontendModal,
  FormElementAutocompleteNew,
  FormElementAutocompleteNewCallbackResult,
  FormState,
  FormSubmitData,
  FormSubmitResult,
  FormSubmitStatusType,
  FormValidationTypeEnum,
  IFormElementOption
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { catchError, filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  getInSafe,
  isNullOrUndefined,
  jsonEqual,
  JsonPathTryEvaluateNew,
  UtilsTypescript
} from '../../../../utils/typescript.utils';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { DecoupledModalBridgeService } from '../../../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { EventFormSucceededInterface } from '../../../event-form-succeeded.interface';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { NavigationService } from '../../../../../core/navigation/navigation.service';

@Component({
  selector: 'app-autocompletenew',
  templateUrl: './autocompletenew.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AutocompletenewComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => AutocompletenewComponent), multi: true}
  ]
})
export class AutocompletenewComponent extends FrontendFormElementInput implements OnInit {

  /**
   * Caché para elementos renderizados
   */
  protected renderCache: IFormElementOption[] = [];

  /**
   * Elementos seleccionados actualmente en el componente (el valor!)
   */
  protected selectedItems: CoreHashedKey[];

  /**
   * Elementos renderizados de los valores seleccionados
   */
  renderedSelectedItems: IFormElementOption[] = [];

  /**
   * Elementos renderizados de la búsqueda de usuario
   */
  renderedSearchItems: IFormElementOption[] = [];

  @ViewChild('inputGroup') inputGroup;

  private renderSelectedValuesUpdated: EventEmitter<void> = new EventEmitter<void>();

  private renderSearchUpdated: EventEmitter<void> = new EventEmitter<void>();

  private currentPage = 0;

  private currentSearch = '';

  private lastPage = false;

  optionsWidth: number;

  /**
   * If a search result has been fetched
   */
  searched = false;

  /**
   * If a serach is in progress
   */
  searching = false;

  /**
   * The search string input by the user
   */
  searchValue: string;

  /**
   * Connection Error
   */
  connectionError = false;

  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected _eref: ElementRef,
              protected dbs: DecoupledModalBridgeService,
              protected localeService: TranslatorService,
              private navigationService: NavigationService) {
    super(formManagerService, cdRef, localeService);
  }

  get elementAutocomplete(): FormElementAutocompleteNew {
    return this.config.FormElement as FormElementAutocompleteNew;
  }

  /**
   * Condiciones para mostrar o no la posiblidad de añadir un nuevo elemento
   */
  get showCreateNewItem(): boolean {
    // Si se ha habilitado en backend
    return this.elementAutocomplete.ShowCreateNewItem;
  }

  /**
   * Condiciones para mostrar o no la posiblidad de editar un elemento
   */
  get showEditItem(): boolean {
    // Si se ha habilitado en backend
    return this.elementAutocomplete.ShowEditItem
        // Si tengo algo seleccionado y soy de selección individual
        && (!this.emptyValue(this.selectedItems));
  }

  /**
   * Getter for the _acValue
   * @returns {any}
   */
  get renderedSelection(): IFormElementOption[] {
    return this.renderedSelectedItems;
  }

  /**
   * Se utiliza en el template para facilitar la manipulación del modo de selección única
   */
  get renderedSelectionSingleItem(): IFormElementOption {
    if (this.renderedSelectedItems.length !== 1) {
      return null;
    }
    return this.renderedSelectedItems[0];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderCache = this.elementAutocomplete.PreloadedOptions ?? [];
  }

  doValidate(c: AbstractControl): ValidationErrors {

    const errors: ValidationErrors = super.doValidate(c);

    if (this.config.isMultiselect && this.config.maxElements > 0 && this.renderedSelection.length > this.config.maxElements) {
      errors[FormValidationTypeEnum.MaxElements] = `El número máximo de elementos es de ${this.config.maxElements} para el campo ${this.config.label}`;
    }

    return errors;
  }

  /**
   * This handler is called when the document click event
   * is fired
   *
   * ACHSPRIME-2168 Revisado funciona OK
   *
   * @param e
   */
  @HostListener('document:click', ['$event'])
  clickedOutside(event: Event): void {
    if (!this._eref.nativeElement.contains(event.target) && this.searched) {
      this.clearSearch();
    }
  }

  /**
   * Close search results when escape key is presed
   * @param event
   */
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (this.searched) {
      this.clearSearch();
    }
  }

  /**
   * Clear search
   */
  clearSearch(): void {
    this.renderedSearchItems = [];
    this.searched = false;
    this.searching = false;
    this.searchValue = null;
    this.forceDetectChanges();
    this.cdRef.detectChanges();
  }

  /**
   * This function is used to communicate with the server and get
   * the options list
   * @param {string} search: search string. Is used in backend to
   * return the values that contains this string
   * @param page
   */
  private getOptionList(search: string, page: number): void {

    // Evitar solapar búsquedas
    if (this.searching === true) {
      return;
    }

    this.renderSearchUpdated.next();

    const formSubmitData: FormSubmitData = new FormSubmitData();
    formSubmitData.formInput = this.formManagerService.getFormComponentValue('');
    formSubmitData.submitElement = this.config.name;

    this.searching = true;
    this.connectionError = false;
    this.cdRef.detectChanges();

    if (isNullOrUndefined(search)) {
      search = '';
    }

    this.formManagerService.getFieldautocompletecallbackNew(
        formSubmitData,
        this.config.name,
        search,
        page
    )
        .pipe(
            catchError((err) => {
              if (err.status === 0) {
                this.connectionError = true;
                this.searching = false;
                this.forceDetectChanges();
                return EMPTY;
              }
              return throwError(err);
            }),
            finalize(() => {
              this.searching = false;
              this.cdRef.detectChanges();
            }),
            takeUntil(this.componentDestroyed$),
            takeUntil(this.renderSearchUpdated),
            take(1)
        )
        .subscribe(
            (response: FormElementAutocompleteNewCallbackResult) => {

              this.searched = true;

              const resultOptions: IFormElementOption[] = response.Options;

              if (page === 0) {
                this.lastPage = response.LastPage;
                this.optionsWidth = this.inputGroup.nativeElement.offsetWidth;
              }

              this.renderedSearchItems = [...this.renderedSearchItems, ...resultOptions];

              this.forceDetectChanges();
            }
        );
  }

  /**
   * This function is called when input autocomplete element
   * get focus
   */
  inputTouched(): void {
    this.propagateTouch();
  }

  /**
   * Add a handler for events keydown.enter on components acomplete.
   *
   * Keydown is used because the forms trigger the submit in keydown.enter
   * events, the custom form components do not stop the propagation of
   * keydown.enter by default as do other native elements such as textareas,
   * empty input fields and others.
   * @see https://stackoverflow.com/questions/40909585/angular-2-how-to-prevent-a-form-from-submitting-on-keypress-enter
   * @see https://stackoverflow.com/questions/37362488/how-can-i-listen-for-keypress-event-on-the-whole-page
   * @param {KeyboardEvent} e Keyboard event
   */
  keydownHandler(e: KeyboardEvent): void {
    e.preventDefault();
  }

  /**
   * This method ask for new items if calback is paged
   */
  scrolledToEndHandler(): void {
    if (this.lastPage || this.searching === true) {
      return;
    }
    this.currentPage++;
    this.getOptionList(this.currentSearch, this.currentPage);
  }

  /**
   * This function is used to trigger an options
   * search. The keyup.enter Event is used to know the current
   * value of the input
   * @param e: keyup.enter Event.
   */
  searchForResults(): void {
    this.renderedSearchItems = [];
    this.currentPage = 0;
    this.currentSearch = this.searchValue;
    this.getOptionList(this.currentSearch, 0);
  }

  /**
   * Si debo o no mostrar el searchbox
   */
  get showSearchBox(): boolean {
    // Cuando es multiselect siempre se muestra
    if (this.elementAutocomplete.IsMultiselect) {
      return true;
    }
    // Si no es multi-select, dependerá de si hay una selección activa o no
    if (this.selectedItems.length > 0) {
      return false;
    }
    return true;
  }

  /**
   * Selecciona una de las opciondes de la búsqueda actual
   *
   * @param option
   */
  setOption(option: IFormElementOption): void {

    if (this.getIsDisabled() === true) {
      return;
    }

    const aux: CoreHashedKey[] = this.elementAutocomplete.IsMultiselect ? [...this.selectedItems] : [];

    if (aux.some((i) => i.Key === option.HashedKey.Key) === false) {
      aux.push(option.HashedKey);
      this.addOptionToRenderCache(option);
    }

    this.clearSearch();
    this.writeValue(aux);

    this.propagateTouch();
    this.propagateChange(this.selectedItems);

    this.forceDetectChanges();
  }

  /**
   * This function is used to set the selected option to null
   */
  unsetOption(key: CoreHashedKey): void {
    if (this.getIsDisabled() === true) {
      return;
    }

    const aux: CoreHashedKey[] = this.selectedItems.filter((i) => i.Key !== key.Key);
    this.writeValue(aux);

    this.propagateTouch();
    this.propagateChange(this.selectedItems);

    this.forceDetectChanges();
  }

  /**
   * Add an option to the render cache if it does not exist
   *
   * @param option
   */
  addOptionToRenderCache(option: IFormElementOption): void {
    if (this.renderCache.some((i) => i.HashedKey.Key === option.HashedKey.Key)) {
      return;
    }
    this.renderCache = [...this.renderCache, option];
  }

  /**
   *
   * @param option
   */
  removeOptionFromRenderCache(option: IFormElementOption): void {
    this.renderCache = this.renderCache.filter((i) => !jsonEqual(i.HashedKey, option.HashedKey))
  }

  /**
   * @inheritDoc
   */
  writeValue(value: CoreHashedKey[]): void {

    if (isNullOrUndefined(value)) {
      value = [];
    }

    this.selectedItems = value;
    this.renderSelectedValuesUpdated.next();

    // Actualizamos la versión renderizada de los valores
    this.renderKeys(this.selectedItems)
        .pipe(
            takeUntil(this.renderSelectedValuesUpdated),
            take(1))
        .subscribe(
            (result) => {
              this.renderedSelectedItems = result;

              // Encontramos aquellos items que no pueden ser renderizados.
              const notRenderedItems: CoreHashedKey[] = this.selectedItems
                  .filter(i => !this.renderedSelectedItems.find(j => j.HashedKey.Key === i.Key));

              // Si existe algún item que no puede ser renderizado, lo eliminamos
              // del arreglo de elementos a renderizar.
              if (notRenderedItems.length > 0) {
                notRenderedItems.forEach(nritem => {
                  const index: number = this.selectedItems
                      .findIndex(selectedItem => selectedItem.Key === nritem.Key);

                  this.selectedItems.splice(index, 1);
                });

                this.propagateChange(this.selectedItems);
              }

              this.forceDetectChanges();
            }
        );
  }

  /**
   * Renderiza las claves dadas
   *
   * @param keys
   */
  renderKeys(keys: CoreHashedKey[]): Observable<IFormElementOption[]> {
    this.renderSelectedValuesUpdated.next();

    // Buscamos los elementos en la caché de renderizado
    const existingRenderedKeys: IFormElementOption[] = this.renderCache.filter((i) => keys.find((j) => j.Key === i.HashedKey.Key));
    const missingKeys: CoreHashedKey[] = keys.filter((i) => !existingRenderedKeys.find((j) => j.HashedKey.Key === i.Key));

    if (missingKeys.length === 0) {
      return of(existingRenderedKeys);
    }

    // Actualizamos la versión renderizada de los valores
    return this.formManagerService.materializationComplete
        .pipe(
            take(1),
            switchMap(() => {
              const formSubmitData: FormSubmitData = new FormSubmitData();
              formSubmitData.formInput = this.formManagerService.getFormComponentValue('');
              formSubmitData.submitElement = this.config.name;
              return this.formManagerService.getFieldautocompletecallbackNew(
                  formSubmitData,
                  this.config.name,
                  null,
                  0,
                  missingKeys
              );
            }),
            takeUntil(this.componentDestroyed$),
            take(1),
            map((response: FormElementAutocompleteNewCallbackResult) => {
                  if (response.Options.length !== missingKeys.length) {
                    console.warn(this.config.ClientId + 'Los siguientes objetos devueltos por el proceso no se han podido renderizar: ', missingKeys);
                  }
                  this.renderCache = [...this.renderCache, ...response.Options];
                  return [...existingRenderedKeys, ...response.Options];
                }
            )
        );
  }

  /**
   * Si está o no vacío
   *
   * @param value
   */
  emptyValue(value: any): boolean {
    if (value && value.length > 0) {
      return false;
    }
    return true;
  }

  /**
   * Get the no results message
   */
  getNoResultsMessage(): string {
    return this.elementAutocomplete.NoResultsMessage;
  }

  /**
   * Handler para el botón de crear nuevo item
   */
  createNewItem(): void {
    if (this.getIsDisabled() === true) {
      return;
    }

    const modalSettings: DtoFrontendModal = this.getModalSettingsNew();

    // A los argumentos existentes, le añadimos unos argumentos automáticos
    // que pueden permitir tener conocimiento de "donde vengo"
    const formArguments: {
      [key: string]: any
    } = UtilsTypescript.jsonClone(this.elementAutocomplete.CreateNewItemFormPluginRequest.Arguments) || {};

    const state: FormState = this.formManagerService.getFormState();
    formArguments['request_form_id'] = state.FormId;
    formArguments['request_form_field_path'] = this.config.ClientPath;
    formArguments['request_form_values'] = this.formManagerService
        .getForm()
        .getRawValue();

    // Al preparar los argumentos para el formulario, vamos a tratar de expandirlos usando los
    // valores del formulario
    if (!isNullOrUndefined(formArguments)) {
      for (const argumentName of Object.keys(formArguments)) {
        const argumentValue: string = formArguments[argumentName];
        JsonPathTryEvaluateNew<string>(
            argumentValue,
            formArguments['request_form_values'],
            (value) => {
              formArguments[argumentName] = value;
            },
            (e) => {
              formArguments[argumentName] = argumentValue;
            });
      }
    }

    const modal: ModalReference<EventFormSucceededInterface> = this.dbs
        .showForm(
            this.elementAutocomplete.CreateNewItemFormPluginRequest.FormId,
            modalSettings,
            formArguments);

    modal.close$
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((i) => !!i),
            map((i) => i.responseData as FormSubmitResult)
        ).subscribe(
        (i: FormSubmitResult) => {
          if (i.Status === FormSubmitStatusType.Success) {
            const personId: CoreHashedKey = i.Result;
            this.renderKeys([personId])
                .pipe(
                    takeUntil(this.componentDestroyed$),
                    take(1)
                )
                .subscribe((renderedOptions) => {
                  this.setOption(renderedOptions[0]);
                });
          }
        });
  }

  /**
   * Handler para el botón de editar item
   */
  editItem(option: IFormElementOption): void {
    if (this.getIsDisabled() === true) {
      return;
    }

    const modalSettings: DtoFrontendModal = this.getModalSettingsEdit();

    // A los argumentos existentes, le añadimos unos argumentos automáticos
    // que pueden permitir tener conocimiento de "donde vengo"
    const formArguments: {
      [key: string]: any
    } = UtilsTypescript.jsonClone(this.elementAutocomplete.EditItemFormPluginRequest.Arguments) || {};

    // Para le edición hace falta el ID del objeto
    formArguments['objectId'] = option.HashedKey;

    const state: FormState = this.formManagerService.getFormState();
    formArguments['request_form_id'] = state.FormId;
    formArguments['request_form_field_path'] = this.config.ClientPath;
    formArguments['request_form_values'] = this.formManagerService
        .getForm()
        .getRawValue();

    const modal: ModalReference<EventFormSucceededInterface> = this.dbs
        .showForm(
            this.elementAutocomplete.EditItemFormPluginRequest.FormId,
            modalSettings,
            formArguments);

    modal.close$
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((i) => !!i),
            map((i) => i.responseData as FormSubmitResult)
        ).subscribe(
        (i: FormSubmitResult) => {
          if (i.Status === FormSubmitStatusType.Success) {
            // Hay que renderizar todos los elementos de nuevo...
            // pero borrrar de la caché de renderizado el que hemos modificado
            this.removeOptionFromRenderCache(option);
            // Repintamos todo
            this.renderKeys(this.selectedItems)
                .pipe(
                    takeUntil(this.componentDestroyed$),
                    take(1)
                )
                .subscribe((renderedOptions) => {
                  this.setOption(renderedOptions[0]);
                });
          }
        });
  }

  getModalSettingsNew(): DtoFrontendModal {
    if (isNullOrUndefined(this.elementAutocomplete.CreateNewItemFormPluginRequest.ModalSettings)) {
      return this.createDefaultModalSettings();
    }
    return this.elementAutocomplete.CreateNewItemFormPluginRequest.ModalSettings;
  }

  getModalSettingsEdit(): DtoFrontendModal {
    if (isNullOrUndefined(this.elementAutocomplete.EditItemFormPluginRequest.ModalSettings)) {
      return this.createDefaultModalSettings();
    }
    return this.elementAutocomplete.EditItemFormPluginRequest.ModalSettings;
  }

  createDefaultModalSettings(): DtoFrontendModal {
    const modalSettings: DtoFrontendModal = new DtoFrontendModal();
    modalSettings.HideClose = true;
    return modalSettings;
  }

  /**
   * @inheritDoc
   */
  equalValues(valueA: any, valueB: any): boolean {
    const valueA2: string = getInSafe(valueA as IFormElementOption, (i) => i.Key, valueA);
    const valueB2: string = getInSafe(valueB as IFormElementOption, (i) => i.Key, valueB);
    return valueA2 === valueB2;
  }
}
