import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  OnInit,
  Renderer2,
  Type,
  ViewContainerRef
} from '@angular/core';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { ListComponent2Service } from '../list.service';
import { ViewsinitializedEventdata } from '../events/viewsinitialized.eventdata';
import {
  IViewMode,
  ViewConfiguration,
  ViewUserConfiguration
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined } from '../../utils/typescript.utils';
import { IViewHeaderComponent } from './view-header-component.interface';
import { ViewHeaderComponentsConstants } from './view-header-components.constants';
import { PrimeUtils } from '../../utils/prime.utils';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appViewHeader]'
})
export class ViewHeaderDirective extends DestroyableObjectTrait implements OnInit {

  /**
   * ViewMode component to render.
   */
  component: ComponentRef<IViewHeaderComponent>;

  /**
   * `ViewModeDirective` class constructor.
   *
   * @param {ComponentFactoryResolver} resolver Component factory resolver.
   * @param {ViewContainerRef} container Container reference.
   * @param {ListComponent2Service} listComponentConfiguration  View component service.
   */
  constructor(
      private resolver: ComponentFactoryResolver,
      private container: ViewContainerRef,
      private listComponentConfiguration: ListComponent2Service,
      protected _renderer: Renderer2) {
    super();
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.listComponentConfiguration
        .viewIntialized
        .pipe(takeUntil(this.componentDestroyed$)).subscribe(
        (next: ViewsinitializedEventdata) => {
          this.CreateComponent(next.configuration, next.userConfiguration)
        });
  }

  /**
   * Given a `ViewUserConfiguration` the `CreateComponent` method instantiates
   * a custom view mode component and displays it on the container.
   *
   * @param {ViewUserConfiguration} userConfiguration User configuration.
   */
  private CreateComponent(configuration: ViewConfiguration, userConfiguration: ViewUserConfiguration): void {

    if (isNullOrUndefined(configuration)) {
      return;
    }

    if (!isNullOrUndefined(this.component)) {
      this.container.clear();
    }

    const selectedViewModeType: string = configuration.ViewHeader.$type;
    const constant: Type<IViewHeaderComponent>
        = ViewHeaderComponentsConstants[selectedViewModeType];

    if (isNullOrUndefined(constant)) {
      return;
    }

    this.component = this.container.createComponent(constant);
    const viewMode: string = PrimeUtils.ParseKeyItemToArray<IViewMode>(configuration.AvailableViewModes).find(x => x.Type === userConfiguration.CurrentViewModeType).Id
    this._renderer.addClass(this.component.instance.getElementRef().nativeElement, 'current-view-mode-' + viewMode);
  }
}
